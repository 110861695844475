/*
* Turn flat WP menus into nested structures
* Query must look like:
* {
      wpMenu(locations: {eq: PRIMARY}) {
        menuItems {
          nodes {
            parentId // needed to resolve to relationship
            id // needed to resolve to relationship
          }
        }
      }
    }
*  */

export const wordpressFlatMenuToHierarchical = (data: any[] = []) => {
  const tree = [];
  const children = {};
  data.forEach((item) => {
    const newItem = { ...item };
    const { id: id, parentId: parentId = 0 } = newItem;
    children[id] = children[id] || [];
    newItem.childMenu = children[id];
    parentId
      ? (children[parentId] = children[parentId] || []).push(newItem)
      : tree.push(newItem);
  });
  return tree;
};
