import React from "react";

function SearchIcon({ className, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="18"
      fill="none"
      viewBox="0 0 17 18"
      className={className}
      {...props}
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M10.774 12.635a6.653 6.653 0 111.128-1.128l4.411 4.412-1.128 1.128-4.411-4.412zm-.53-8.807a5.052 5.052 0 00-7.15 0 5.052 5.052 0 000 7.15 5.052 5.052 0 007.15 0 5.052 5.052 0 000-7.15z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default SearchIcon;
