/** @jsxImportSource @compiled/react */
const Divider = ({
  className,
  ...props
}: CompiledJSXPropsOmitRef<HTMLHRElement>): JSX.Element => {
  return (
    <hr
      aria-orientation="horizontal"
      css={{
        opacity: 0.6,
        borderColor: "#A4A3A2",
        borderStyle: "solid",
        borderTopWidth: "1px",
        width: "100%",
      }}
      className={className}
      {...props}
    />
  );
};

export default Divider;
