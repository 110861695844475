/** @jsxImportSource @compiled/react */
import { ContainerWrap } from "./container-wrap";
import { Twirl as Hamburger } from "hamburger-react";
import { AiFillCaretDown } from "react-icons/ai";
import { ShareBar } from "./share-bar";
import {Drawer, DrawerContent} from "./primitives/drawer";
import Accordion, {
  AccordionItem,
  AccordionPanel,
} from "./primitives/accordion";
import { AccordionTrigger } from "@radix-ui/react-accordion";
import { Link } from 'gatsby'
import {useLocation} from "@reach/router";
import React from "react";
import * as DialogPrimitive from '@radix-ui/react-dialog'

export const MobileMenu = ({ menu, socialMedia, portal }) => {

    const [isOpen, setOpen] = React.useState(false)
  return (
    <Drawer open={isOpen}>
        <div css={{
            "@media (min-width: 80em)": {
                display: "none",
            },
        }}>
            <HamburgerMenuIcon
                isOpen={isOpen}
                onClose={()=>setOpen(false)}
                onOpen={()=>setOpen(true)}
            />
        </div>
      <DrawerContent
          container={portal}
        placement="left"
        size="full"
        css={{ backgroundColor: "black" }}
      >
        <ContainerWrap>
          <nav
            css={{
              marginBottom: "28px",
              marginTop: "128px",
              overflowY: "auto",
              height: "calc(100vh - 380px)",
            }}
          >
            <Accordion
              allowToggle
              css={{
                width: "100%",
              }}
            >
              {menu.map((childItemSet) => (
                <AccordionItem>
                  <AccordionTrigger
                    css={{
                      display: "flex",
                      lineHeight: "16px",
                      fontSize: "16px",
                      textDecoration: "none",
                      border: "0",
                      overflow: "hidden",
                      justifyContent: "space-between",
                      padding: "12px 0",
                      width: "100%",
                      transition: "0.3s",
                      "&:hover": {
                        color: "#a4a3a2",
                      },
                      '&[data-state="open"]': {
                        ".caret": {
                          transform: "rotate(180deg)",
                        },
                        color: "#a4a3a2",
                      },
                    }}
                  >
                    <div
                      css={{
                        textAlign: "left",
                        width: "100%",
                      }}
                    >
                      {childItemSet.label}
                    </div>
                    <div
                      css={{
                        right: "5px",
                      }}
                    >
                      <div
                        css={{
                          transition: "0.3s",
                          height: "10px",
                          width: "10px",
                        }}
                      >
                        <AiFillCaretDown
                          className="caret"
                          style={{
                            transition: "0.3s",
                            width: "10px",
                            height: "10px",
                          }}
                        />
                      </div>
                    </div>
                  </AccordionTrigger>
                  <AccordionPanel
                    css={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      paddingTop: "10px",
                      paddingBottom: "20px",
                      "&>:not(style)~:not(style)": {
                        marginTop: "20px",
                      },
                    }}
                  >
                    {childItemSet.childMenu.length !== 0 &&
                      childItemSet.childMenu.map((item) => (
                        <Link
                          to={item.url}
                          onClick={()=>setOpen(false)}
                          css={{
                            width: "100%",
                            display: "block",
                            fontSize: "13px",
                            transition: "0.3s",
                            textDecoration: "none",
                            "&:hover": {
                              color: "#a4a3a2",
                            },
                          }}
                        >
                          {item.label}
                        </Link>
                      ))}
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </nav>

          <div
            css={{
              display: "flex",
              alignItems: "flex-end",
              flexGrow: 1,
            }}
          >
            <div
              css={{
                width: "100%",
              }}
            >
              <div
                css={{
                  borderTop: "1px solid #a4a3a299",
                  width: "100%",
                }}
              >
                <div
                  css={{
                    padding: "30px 0",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    css={{
                      maxWidth: "87px",
                      lineHeight: "20px",
                      fontSize: "13px",
                      width: "100%",
                      color: "#fff",
                    }}
                  >
                    Find us on social media
                  </div>
                  <ShareBar {...socialMedia} />
                </div>
              </div>
              <div
                css={{
                  opacity: "0.7",
                  marginBottom: "72px",
                  borderTop: "1px solid #a4a3a299",
                  width: "100%",
                }}
              >
                <div
                  css={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2,1fr)",
                    gap: 6,
                    paddingTop: "30px",
                    width: "100%",
                  }}
                >
                  <div
                    css={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      justifyContent: "start",
                      width: "100%",
                    }}
                  >
                    <div
                      css={{
                        lineHeight: "20px",
                        fontSize: "11px",
                        width: "100%",
                        color: "#e0e0e0",
                      }}
                    >
                      All rights reserved <br />
                      VICPROP {new Date().getFullYear()}.
                    </div>
                  </div>
                  <div
                    css={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                      alignItems: "start",
                      justifyContent: "start",
                      width: "100%",
                    }}
                  >
                    <Link
                      to="/terms-and-conditions"
                      css={{
                        lineHeight: "20px",
                        fontSize: "11px",
                        width: "100%",
                        color: "#e0e0e0",
                      }}
                      onClick={()=>setOpen(false)}
                    >
                      Terms & Conditions
                    </Link>
                    <Link
                        onClick={()=>setOpen(false)}
                      to="/privacy-policy"
                      css={{
                        lineHeight: "20px",
                        fontSize: "11px",
                        width: "100%",
                        color: "#e0e0e0",
                      }}
                    >
                      Privacy Policy
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContainerWrap>
      </DrawerContent>
    </Drawer>
  );
};

export const HamburgerMenuIcon = ({
  isOpen,
  className = "",
    onClose,
    onOpen,
  ...props
}) => {

    const location = useLocation()
    const isVisible = location.pathname === "/search"

  return (
    <div
      className={className}
      css={{
        transition: "0.3s",
        marginRight: "-4px",
          width: isVisible ? "0" : "48px",
          paddingLeft: isVisible ? "0" : "10px",
          transition: "0.3s",
          pointerEvents: isVisible ? "none" : "auto",
        "@media (min-width: 80em)": {
          marginRight: "0",
        },
      }}
      {...props}
    >
      <div
        css={{
          transition: "0.3s",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          height: "48px",
            opacity: isVisible ? 0 : 1,
            width: isVisible ? "0" : "48px"
        }}
      >
        <div
          css={{
            position: "absolute",
            borderRadius: "300px",
            transition: "background-color 0.3s",
            height: "40px",
            width: "40px",
          }}
        />
        <Hamburger
          size={19}
          toggled={isOpen}
          toggle={isOpen ? onClose : onOpen}
        />
      </div>
    </div>
  );
};
