exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-ssr-search-tsx": () => import("./../../../src/templates/ssr-search.tsx" /* webpackChunkName: "component---src-templates-ssr-search-tsx" */),
  "component---src-templates-wp-office-tsx": () => import("./../../../src/templates/WpOffice.tsx" /* webpackChunkName: "component---src-templates-wp-office-tsx" */),
  "component---src-templates-wp-page-tsx": () => import("./../../../src/templates/WpPage.tsx" /* webpackChunkName: "component---src-templates-wp-page-tsx" */),
  "component---src-templates-wp-project-tsx": () => import("./../../../src/templates/WpProject.tsx" /* webpackChunkName: "component---src-templates-wp-project-tsx" */),
  "component---src-templates-wp-property-tsx": () => import("./../../../src/templates/WpProperty.tsx" /* webpackChunkName: "component---src-templates-wp-property-tsx" */),
  "component---src-templates-wp-rental-tsx": () => import("./../../../src/templates/WpRental.tsx" /* webpackChunkName: "component---src-templates-wp-rental-tsx" */)
}

