/** @jsxImportSource @compiled/react */
import SearchIcon from "./svg/search-icon";
import { Cross2Icon } from "@radix-ui/react-icons";
import {useLocation, navigate} from "@reach/router";

export const Search = (): JSX.Element => {

    const location = useLocation()
  const isOpen = location.pathname === "/search/"

  return (
    <>
      <div
        role="button"
        css={{
          width: "40px",
          height: "auto",
          backgroundColor: "transparent",
          transition: "0.3s",
          borderRadius: "500px",
          display: "inline-flex",
          appearance: "none",
          alignItems: "center",
          justifyContent: "center",
          userSelect: "none",
          position: "relative",
          whiteSpace: "nowrap",
          verticalAlign: "middle",
          cursor: "pointer",
          outlineOffset: "2px",
          ":active div": {
            color: "#312e2e",
            boxShadow: "0",
            backgroundColor: "white",
          },
          ":hover div": {
            color: "#312e2e",
            boxShadow: "0",
            backgroundColor: "white",
          },
          ":focus div": {
            color: "#312e2e",
            boxShadow: "0",
            backgroundColor: "white",
          },
        }}
        aria-label="Search"
        onClick={
          !isOpen
            ? () => {
               navigate("/search/")
              }
            : () => {
                  navigate(-1);
              }
        }
      >
        <div
          css={{
            height: "40px",
            width: "40px",
            minHeight: "40px",
            maxHeight: "40px",
            minWidth: "40px",
            maxWidth: "40px",
            transition: "0.3s",
            display: "flex",
            borderRadius: "500px",
            padding: "12px",
            animation: isOpen ? "" : "pulse 2s infinite",
            backgroundColor: isOpen ? "#312E2E" : "white",
              boxShadow: "0 0 12px #0005",
            alignItems: "center",
            justifyContent: "center",
            "& svg": {
              verticalAlign: "middle",
              display: "inline-block",
              flexShrink: 0,
              color: "currentcolor",
              alignSelf: "center",
              justifySelf: "center",
            },
          }}
          aria-hidden={true}
        >
          {isOpen ? <Cross2Icon width={24} height={24} /> : <SearchIcon />}
        </div>
      </div>
    </>
  );
};
