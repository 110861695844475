import React from "react";

function Linkedin() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="18"
      fill="none"
      viewBox="0 0 17 18"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M.413 6.065h3.19v11.116H.412V6.065zm1.513-1.39h-.023C.75 4.675 0 3.825 0 2.749 0 1.651.77.82 1.948.82c1.177 0 1.9.83 1.924 1.927 0 1.076-.747 1.929-1.946 1.929zm14.438 12.507h-3.617v-5.753c0-1.506-.566-2.533-1.81-2.533-.951 0-1.48.693-1.727 1.362-.092.24-.078.573-.078.909v6.015H5.55s.047-10.19 0-11.117h3.583V7.81c.212-.763 1.357-1.852 3.184-1.852 2.267 0 4.048 1.6 4.048 5.042v6.182z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Linkedin;
