import React from "react";

function Youtube() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="17"
      fill="none"
      viewBox="0 0 23 17"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M22.286 12.12V4.37s0-3.735-3.747-3.735H4.291S.545.636.545 4.371v7.749s0 3.735 3.746 3.735h14.248s3.747 0 3.747-3.735zm-6.65-3.864L8.52 12.423V4.088l7.117 4.168z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Youtube;
