import React from "react";

function Instagram() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      fill="none"
      viewBox="0 0 18 19"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M5.401.818h7.38c2.77 0 5.037 2.238 5.037 4.972v7.36c0 2.734-2.266 4.972-5.038 4.972H5.402c-2.77 0-5.037-2.238-5.037-4.972V5.79C.364 3.056 2.63.818 5.4.818zm3.643 3.98a4.88 4.88 0 014.878 4.878 4.88 4.88 0 01-9.757 0 4.88 4.88 0 014.879-4.878zm0 1.648a3.231 3.231 0 11-.002 6.463 3.231 3.231 0 01.002-6.463zm4.719-2.324a.792.792 0 110 1.584.792.792 0 010-1.584zm-7.759-1.89h6.174c2.318 0 4.214 1.886 4.214 4.192v6.205c0 2.305-1.896 4.191-4.214 4.191H6.004c-2.318 0-4.214-1.886-4.214-4.191V6.424c0-2.306 1.896-4.192 4.214-4.192z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Instagram;
