/** @jsxImportSource @compiled/react */
import "./reset.css";
import {graphql, PageProps, useStaticQuery} from "gatsby";
import FadeTransition from "../components/transitions/fade-transition";
import Footer from "../components/footer";
import "keen-slider/keen-slider.min.css";
import { Header } from "../components/header";
import "plyr/dist/plyr.css";
import "./layout.scss";
import "focus-visible/dist/focus-visible";
import React from "react";
import { SEOContext } from "gatsby-plugin-wpgraphql-seo"

const Layout = ({ children, location }: PageProps): JSX.Element => {

    const [drawerPortal, setDrawerPortal] = React.useState(null)

    const {
        wp: { seo },
    } = useStaticQuery(graphql`
        query SiteInfoQuery {
            wp {
                seo {
                    contentTypes {
                        post {
                            title
                            schemaType
                            metaDesc
                        }
                        page {
                            metaDesc
                            schemaType
                            title
                        }
                    }
                    webmaster {
                        googleVerify
                        yandexVerify
                        msVerify
                        baiduVerify
                    }
                    schema {
                        companyName
                        personName
                        companyOrPerson
                        wordpressSiteName
                        siteUrl
                        siteName
                        inLanguage
                        logo {
                            sourceUrl
                            mediaItemUrl
                            altText
                        }
                    }
                    social {
                        facebook {
                            url
                            defaultImage {
                                sourceUrl
                                mediaItemUrl
                            }
                        }
                        instagram {
                            url
                        }
                        linkedIn {
                            url
                        }
                        mySpace {
                            url
                        }
                        pinterest {
                            url
                            metaTag
                        }
                        twitter {
                            username
                        }
                        wikipedia {
                            url
                        }
                        youTube {
                            url
                        }
                    }
                }
            }
        }
    `)


  return (
      <SEOContext.Provider value={{ global: seo }}>
    <div
      css={{
        backgroundColor: "black",
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <div
        css={{
          display: "flex",
          minHeight: "100vh",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Header drawerPortal={drawerPortal} />
        <div
          css={{
            display: "flex",
            minHeight: "100vh",
            width: "100%",
            flexDirection: "column",
            backgroundColor: "black",
          }}
        >
            {children}
          <Footer />
          <div ref={setDrawerPortal} />
        </div>
      </div>
    </div>
      </SEOContext.Provider>
  );
};

export default Layout;
