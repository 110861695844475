/** @jsxImportSource @compiled/react */

export const ContainerWrap = ({
  className,
  ...props
}: CompiledJSXPropsOmitRef<HTMLDivElement>): JSX.Element => {
  return (
    <section
      css={{
        display: "flex",
        position: "relative",
        justifyContent: "center",
        flexGrow: "1",
        width: "100%",
        padding: "0 32px",
      }}
    >
      <div
        className={className}
        css={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          maxWidth: "1205px",
        }}
        {...props}
      />
    </section>
  );
};
