import React from "react";

function Twitter() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="16"
      fill="none"
      viewBox="0 0 18 16"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M15.249 2.965A3.69 3.69 0 0016.798.909a6.836 6.836 0 01-2.24.902A3.431 3.431 0 0011.987.636c-1.946 0-3.524 1.665-3.524 3.718 0 .291.03.575.09.846-2.928-.155-5.525-1.633-7.265-3.884a3.865 3.865 0 00-.477 1.87c0 1.29.622 2.427 1.568 3.095a3.399 3.399 0 01-1.597-.465v.046c0 1.802 1.214 3.305 2.829 3.645-.297.087-.608.131-.93.131-.227 0-.449-.022-.663-.066.448 1.476 1.75 2.553 3.293 2.582a6.843 6.843 0 01-5.22 1.54 9.611 9.611 0 005.404 1.67c6.485 0 10.029-5.665 10.029-10.578 0-.162-.002-.323-.01-.481a7.37 7.37 0 001.76-1.925 6.757 6.757 0 01-2.024.585z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Twitter;
