import React from "react";

function Facebook() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="18"
      fill="none"
      viewBox="0 0 10 18"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M6.25 17.612H2.69V9.214H.909V6.321h1.78V4.583c0-2.36 1.004-3.765 3.86-3.765h2.376v2.895H7.439c-1.111 0-1.184.404-1.184 1.159L6.25 6.321H8.94l-.315 2.893H6.25v8.398z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Facebook;
