/** @jsxImportSource @compiled/react */
import React from "react";
import Linkedin from "./svg/linkedin";
import Twitter from "./svg/twitter";
import Facebook from "./svg/facebook";
import Instagram from "./svg/instagram";
import Wechat from "./svg/wechat";
import Youtube from "./svg/youtube";
import {useLocation} from "@reach/router";

export interface ShareBarProps {
  linkedinUrl?: string;
  twitterUrl?: string;
  facebookUrl?: string;
  wechatUrl?: string;
  youtubeUrl?: string;
  instagramUrl?: string;
}

export const ShareBar = ({
  linkedinUrl,
  twitterUrl,
  facebookUrl,
  wechatUrl,
  youtubeUrl,
  instagramUrl,
}: ShareBarProps) => {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        "&>*:not(style)~*:not(style)": {
          marginLeft: "20px",
        },
      }}
    >
      {linkedinUrl && (
        <a
          css={{
            transition: "opacity 0.3s",
            ":hover": {
              opacity: "0.8",
            },
          }}
          target="_blank"
          href={linkedinUrl}
        >
          <Linkedin />
        </a>
      )}
      {twitterUrl && (
        <a
          target="_blank"
          css={{
            transition: "opacity 0.3s",
            ":hover": {
              opacity: "0.8",
            },
          }}
          href={twitterUrl}
        >
          <Twitter />
        </a>
      )}
      {facebookUrl && (
        <a
          target="_blank"
          css={{
            transition: "opacity 0.3s",
            ":hover": {
              opacity: "0.8",
            },
          }}
          href={facebookUrl}
        >
          <Facebook />
        </a>
      )}
      {instagramUrl && (
        <a
          target="_blank"
          css={{
            transition: "opacity 0.3s",
            ":hover": {
              opacity: "0.8",
            },
          }}
          href={instagramUrl}
        >
          <Instagram />
        </a>
      )}
      {wechatUrl && (
        <a
          target="_blank"
          css={{
            transition: "opacity 0.3s",
            ":hover": {
              opacity: "0.8",
            },
          }}
          href={wechatUrl}
        >
          <Wechat />
        </a>
      )}
      {youtubeUrl && (
        <a
          target="_blank"
          css={{
            transition: "opacity 0.3s",
            ":hover": {
              opacity: "0.8",
            },
          }}
          href={youtubeUrl}
        >
          <Youtube />
        </a>
      )}
    </div>
  );
};

export const ShareBarPage = () => {

  const location = useLocation()
  const url = encodeURIComponent(`https://vicprop.com.au/${location.pathname}`);
  const title = encodeURIComponent(typeof document !== "undefined" ? document.title : "VICPROP");
  const description = "";

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        "&>*:not(style)~*:not(style)": {
          marginLeft: "20px",
        },
      }}
    >
      <a
        target="_blank"
        css={{
          transition: "opacity 0.3s",
          cursor: "pointer",
          ":hover": {
            opacity: "0.8",
          },
        }}
        href={`https://www.linkedin.com/shareArticle?url=${url}&text=${title}&summary=${description}&mini=true`}
      >
        <Linkedin />
      </a>
      <a
        target="_blank"
        css={{
          transition: "opacity 0.3s",
          cursor: "pointer",
          ":hover": {
            opacity: "0.8",
          },
        }}
        href={`https://twitter.com/share?url=${url}&text=${title}`}
      >
        <Twitter />
      </a>
      <a
        target="_blank"
        css={{
          transition: "opacity 0.3s",
          cursor: "pointer",
          ":hover": {
            opacity: "0.8",
          },
        }}
        href={`https://facebook.com/sharer/sharer.php?u=${url}&t=${title}`}
      >
        <Facebook />
      </a>
      <a
        target="_blank"
        css={{
          transition: "opacity 0.3s",
          cursor: "pointer",
          ":hover": {
            opacity: "0.8",
          },
        }}
        href={`https://chart.apis.google.com/chart?cht=qr&chs=196x196&chld=Q%7C0&chl=${url}`}
      >
        <Wechat />
      </a>
    </div>
  );
};
