import React from "react";

function Wechat() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="18"
      fill="none"
      viewBox="0 0 22 18"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M8.35 0C4.09 0 .637 2.879.637 6.429c0 2.046 1.155 3.862 2.946 5.037L2.565 13.5l2.77-1.187c.595.211 1.212.385 1.871.466a5.383 5.383 0 01-.141-1.208c0-3.544 3.46-6.428 7.714-6.428.39 0 .77.032 1.146.078C15.245 2.25 12.115 0 8.35 0zM5.78 4.821a.965.965 0 110-1.929.965.965 0 010 1.93zm4.178-.964a.965.965 0 101.929 0 .965.965 0 00-1.93 0z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M21.208 11.571c0-2.84-2.879-5.142-6.429-5.142-3.55 0-6.428 2.302-6.428 5.142 0 2.84 2.878 5.143 6.428 5.143.584 0 1.14-.082 1.678-.199L19.922 18l-1.198-2.395c1.501-.941 2.484-2.39 2.484-4.034zm-8.357-.321a.965.965 0 110-1.93.965.965 0 010 1.93zm2.892-.964a.965.965 0 101.93 0 .965.965 0 00-1.93 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Wechat;
