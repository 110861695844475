/** @jsxImportSource @compiled/react */
import React from "react";
import LogoBlack from "./svg/logo-black";
import Divider from "./divider";
import { graphql, Link, useStaticQuery } from "gatsby";
import { wordpressFlatMenuToHierarchical } from "../utils/wordpress-flat-menu-to-hierarchical";
import { ShareBar } from "./share-bar";

const FooterList = ({ label, childMenu }): JSX.Element => {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "40px",
        width: "50%",
        position: "relative",
        "@media (min-width: 80em)": {
          marginBottom: "0",
          width: "auto",
          marginLeft: "74px",
          ":first-of-type": {
            marginLeft: "0",
          },
        },
      }}
    >
      <div css={{ marginBottom: "38px", color: "#a4a3a2" }}>{label}</div>
      <ul
        css={{
          listStyleType: "none",
          color: "#e3e3e3",
        }}
      >
        {childMenu.map(({ label, url }) => (
          <li
            css={{
              marginTop: "24px",
              ":first-of-type": {
                marginTop: "0",
              },
            }}
          >
            <Link className="animated-link" to={url}>
              {label}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};


const Footer = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    {
      wpMenu(locations: { eq: GATSBY_FOOTER_MENU }) {
        menuItems {
          nodes {
            parentId
            label
            id
            url
          }
        }
      }
      wp {
        siteSettings {
          socialMedia {
            facebookUrl
            instagramUrl
            linkedinUrl
            twitterUrl
            wechatUrl
            youtubeUrl
          }
        }
      }
    }
  `);

  const parsedHierarchical = React.useMemo(
    () => wordpressFlatMenuToHierarchical(data?.wpMenu?.menuItems?.nodes),
    []
  );

  const facebookUrl = data.wp.siteSettings.socialMedia.facebookUrl;
  const instagramUrl = data.wp.siteSettings.socialMedia.instagramUrl;
  const linkedinUrl = data.wp.siteSettings.socialMedia.linkedinUrl;
  const twitterUrl = data.wp.siteSettings.socialMedia.twitterUrl;
  const wechatUrl = data.wp.siteSettings.socialMedia.wechatUrl;
  const youtubeUrl = data.wp.siteSettings.socialMedia.youtubeUrl;

  return (
    <footer
      css={{
        display: "flex",
        fontWeight: "500",
        backgroundColor: "#000",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: "1",
        padding: "0 32px",
        borderTop: "1px solid white",
      }}
    >
      <div
        css={{
          display: "flex",
          paddingTop: "63px",
          paddingBottom: "78px",
          flexDirection: "column",
          maxWidth: "1203px",
          width: "100%",
          "@media (min-width: 80em)": {
            paddingTop: "100px",
            paddingBottom: "114px",
          },
        }}
      >
        <LogoBlack />
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "35px",
            marginTop: "57px",
          }}
        >
          <div
            css={{
              width: "auto",
              display: "flex",
            }}
          >
            <div
              css={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              {parsedHierarchical.slice(0, -1).map((list) => (
                <FooterList {...list} />
              ))}
              <div
                css={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "40px",
                  width: "100%",
                  position: "relative",
                  "@media (min-width: 80em)": {
                    marginBottom: "0",
                    width: "auto",
                    marginLeft: "74px",
                    ":first-of-type": {
                      marginLeft: "0",
                    },
                  },
                }}
              >
                <div css={{ marginBottom: "38px", color: "#a4a3a2" }}>
                  {parsedHierarchical[parsedHierarchical.length - 1].label}
                </div>
                <ul
                  css={{
                    listStyleType: "none",
                    color: "#e3e3e3",
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    rowGap: "24px",
                    columnGap: "0",
                    "@media (min-width: 80em)": {
                      columnGap: "24px",
                    },
                  }}
                >
                  {parsedHierarchical[
                    parsedHierarchical.length - 1
                  ].childMenu.map(({ label, url }) => (
                    <li>
                      <Link className="animated-link" to={url}>
                        {label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              width: "auto",
            }}
          >
            <div
              css={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "40px",
              }}
            >
              <ShareBar
                linkedinUrl={linkedinUrl}
                twitterUrl={twitterUrl}
                facebookUrl={facebookUrl}
                instagramUrl={instagramUrl}
                wechatUrl={wechatUrl}
                youtubeUrl={youtubeUrl}
              />
            </div>
          </div>
        </div>
        <Divider
          css={{
            marginBottom: "35px",
          }}
        />
        <div
          css={{
            display: "none",
            flexDirection: "row",
            fontSize: "11px",
            opacity: "0.7",
            "@media (min-width: 80em)": {
              display: "flex",
            },
          }}
        >
          <div css={{ color: "#e0e0e0" }}>
            All rights reserved VICPROP {new Date().getFullYear()}.
          </div>
          <Link
            css={{ marginLeft: "24px", color: "#e0e0e0" }}
            to="/terms-and-conditions"
          >
            Terms & Conditions
          </Link>
          <Link
            css={{ marginLeft: "24px", color: "#e0e0e0" }}
            to="/privacy-policy"
          >
            Privacy Policy
          </Link>
          <div css={{ marginLeft: "24px", color: "#e0e0e0" }}>
            Design by <a href="https://www.lbdstudios.com.au/">LBD Studios</a> +{" "}
            <a href="https://atollon.com.au/">Atollon</a>
          </div>
        </div>
        <div
          css={{
            display: "flex",
            fontSize: "11px",
            opacity: "0.7",
            justifyContent: "space-between",
            "@media (min-width: 80em)": {
              display: "none",
            },
          }}
        >
          <div css={{ display: "flex", flexDirection: "column" }}>
            <Link
              to="/terms-and-conditions"
              css={{ paddingBottom: "7px", color: "#e0e0e0 " }}
            >
              Terms & Conditions
            </Link>
            <Link to="/privacy-policy" css={{ color: "#e0e0e0 " }}>
              Privacy Policy
            </Link>
          </div>
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              color: "#e0e0e0",
            }}
          >
            <div>All rights reserved</div>
            <div css={{ paddingTop: "7px" }}>
              VICPROP {new Date().getFullYear()}. Design by{" "}
              <a href="https://www.lbdstudios.com.au/">LBD</a>
            </div>
            <div css={{ paddingTop: "7px" }}>
              <a href="https://www.lbdstudios.com.au/">Studios</a> +{" "}
              <a href="https://atollon.com.au/">Atollon</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
