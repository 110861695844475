/** @jsxImportSource @compiled/react */
import React from "react";
import LogoBlack from "./svg/logo-black";
import { HamburgerMenuIcon, MobileMenu } from "./mobile-menu";
import { Search } from "./search";
import FadeTransition from "./transitions/fade-transition";
import {graphql, Link, useStaticQuery} from "gatsby";
import { wordpressFlatMenuToHierarchical } from "../utils/wordpress-flat-menu-to-hierarchical";
import Divider from "./divider";
import { ShareBar } from "./share-bar";
import {useLocation} from "@reach/router";
export const Header = ({ drawerPortal }) => {

  const location = useLocation()

    const [isOpen, setIsOpen] = React.useState(false)
  const data = useStaticQuery(graphql`
    {
      wpMenu(locations: { eq: GATSBY_HEADER_MENU }) {
        menuItems {
          nodes {
            parentId
            label
            id
            url
          }
        }
      }
      wp {
        siteSettings {
          socialMedia {
            facebookUrl
            instagramUrl
            linkedinUrl
            twitterUrl
            wechatUrl
            youtubeUrl
          }
        }
      }
    }
  `);
  const parsedHierarchical = React.useMemo(
    () => wordpressFlatMenuToHierarchical(data?.wpMenu?.menuItems?.nodes),
    []
  );

  return (
    <div
      css={{
        padding: "0 32px",
        transition: "0.3s",
        zIndex: "50",
        width: "calc(100% + var(--removed-body-scroll-bar-size))",
        top: "0",
        position: "sticky",
        height: "80px",
          //overflow: "hidden",
        "@media (min-width: 80em)": {
          height: "122px",
        },
      }}
    >
      <div
        className="menu-visible-el"
        css={{
          background: "black",
          transition: "0.4s",
          width: "100%",
          height: "100%",
          left: "0",
          position: "absolute",
        }}
      />
      <DropDownContainer
        isOpen={isOpen}
        onClose={()=>setIsOpen(false)}
        menu={parsedHierarchical}
        socialMedia={data.wp.siteSettings.socialMedia}
      />

      <div
        css={{
          display: "flex",
          padding: "32px 0",
          transition: "0.3s",
          zIndex: "50",
          top: "0",
          position: "sticky",
          margin: "auto",
          maxWidth: "1203px",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          height: "80px",
          "@media (min-width: 80em)": {
            height: "122px",
          },
        }}
      >
        <Link to="/">
          <LogoBlack />
        </Link>

        <div
          css={{
            display: "flex",
            flexDirection: "row",
            fontSize: "14px",
            alignItems: "center",
            gap: "0",
            justifyContent: "space-between",
            "@media (min-width: 80em)": {
              gap: "85px",
              justifyContent: "flex-end",
            },
          }}
        >
          <div
            className="menu-visible-el"
            css={{
              display: "flex",
              transition: "0.3s",
              gap: "85px",
            }}
          >
            <Link
              to="/search"
              className="animated-link"
              css={{
                display: "none",
                "@media (min-width: 80em)": {
                  display: "inline",
                },
              }}
            >
              Buy
            </Link>

            <Link
              to="/sell"
              className="animated-link"
              css={{
                display: "none",
                "@media (min-width: 80em)": {
                  display: "inline",
                },
              }}
            >
              Sell
            </Link>

            <Link
              to="/rent"
              className="animated-link"
              css={{
                display: "none",
                "@media (min-width: 80em)": {
                  display: "inline",
                },
              }}
            >
              Lease
            </Link>

            <Link
              to="/projects"
              className="animated-link"
              css={{
                display: "none",
                "@media (min-width: 80em)": {
                  display: "inline",
                },
              }}
            >
              Projects
            </Link>
          </div>
          <div
            css={{
              display: "flex",

              gap: "0",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Search />
            <HamburgerMenuIcon
              isOpen={isOpen}
              onOpen={()=>setIsOpen(true)}
              onClose={()=>setIsOpen(false)}
              isVisible={!location.pathname === "/search"}
              css={{
                display: "none",
                "@media (min-width: 80em)": {
                  display: "unset",
                },
              }}
            />
            <MobileMenu
              menu={parsedHierarchical}
              socialMedia={data.wp.siteSettings.socialMedia}
              portal={drawerPortal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const DropDownContainer = ({ isOpen, onClose, menu, socialMedia }) => {
  return (
    <div
      css={{
        top: "0",
        bottom: "0",
        width: "100%",
        position: "absolute",
        left: "0",
        display: "none",
        "@media (min-width: 80em)": {
          display: "unset",
        },
      }}
    >
      <FadeTransition shouldChange={isOpen.toString()}>
        {isOpen ? (
          <div
            css={{
              transition: "0.3s",
              height: "100%",
              width: "100%",
            }}
          >
            <div
              onClick={onClose}
              css={{
                width: "100%",
                height: "100vh",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                position: "absolute",
              }}
            />
            <div
              css={{
                padding: "0 32px",
                transition: "0.3s",
                boxShadow: "lg",
                width: "100%",
                paddingTop: "122px",
                backgroundColor: "#000",
                position: "relative",
              }}
            >
              <div
                css={{
                  margin: "auto",
                  transition: "0.3s",
                  width: "100%",
                  maxWidth: "1203px",
                }}
              >
                <div
                  css={{
                    display: "grid",
                    gridTemplateColumns: "repeat(6,1fr)",
                    paddingBottom: "100px",
                    paddingTop: "30px",
                    gap: "30px",
                    width: "100%",
                  }}
                >
                  {menu.map((childItemSet) => (
                    <div
                      css={{
                        width: "100%",
                      }}
                    >
                      <Link
                        to={childItemSet.url ? childItemSet.url : undefined}
                        onClick={onClose}
                        css={{
                          padding: "6px 0",
                          width: "100%",
                          fontWeight: "500",
                          lineHeight: "46px",
                          fontSize: "24px",
                          display: "block",
                          textDecoration: "none",
                        }}
                      >
                        {childItemSet.label}
                      </Link>
                      <div
                        css={{
                          display: "flex",
                          flexDirection: "column",
                          paddingTop: "44px",
                          alignItems: "start",
                          gap: "20px",
                        }}
                      >
                        {childItemSet.childMenu.length !== 0 &&
                          childItemSet.childMenu.map((item) => (
                            <Link
                              onClick={onClose}
                              to={item.url}
                              css={{
                                display: "block",
                                fontSize: "13px",
                                transition: "0.3s",
                                textDecoration: "none",
                              }}
                            >
                              {item.label}
                            </Link>
                          ))}
                      </div>
                    </div>
                  ))}
                </div>
                <Divider
                  css={{
                    marginBottom: "35px",
                  }}
                />
                <div
                  css={{
                    display: "flex",

                    paddingBottom: "35px",
                    opacity: "0.7",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    css={{
                      fontSize: "11px",
                      flexDirection: "row",
                      gap: "24px",
                      display: "none",
                      "@media (min-width: 80em)": {
                        display: "flex",
                      },
                    }}
                  >
                    <div
                      css={{
                        color: "#E0E0E0",
                      }}
                    >
                      All rights reserved VICPROP {new Date().getFullYear()}.
                    </div>
                    <Link
                      to="/terms-and-conditions"
                      className="animated-link"
                      onClick={onClose}
                      css={{
                        color: "#E0E0E0",
                      }}
                    >
                      Terms & Conditions
                    </Link>
                    <Link
                      to="/privacy-policy"
                      className="animated-link"
                      onClick={onClose}
                      css={{
                        color: "#E0E0E0",
                      }}
                    >
                      Privacy Policy
                    </Link>
                  </div>
                  <ShareBar {...socialMedia} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div />
        )}
      </FadeTransition>
    </div>
  );
};
